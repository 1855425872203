<!-- 留言统计 -->
<template>
  <div class="_leaveword">
    <div class="tips flex align-items pf">
      <div class="icon">
        <img
          src="../../assets/img/xinban/zhuzhuang.png"
          class="w-full h-full"
          alt=""
        />
      </div>
      <div class="fontone">{{ "留言情况统计" }}</div>
      <!-- <div class="fonttwo">{{ '(提示:单击表头单元格进行排序,单击单位名称连接到单位留言列表。)' }}</div> -->
    </div>
    <!-- 表格 -->
    <div class="tab pf1">
      <!-- 表头 -->
      <div class="tab_head">
        <div class="tab_one fontThree">{{ "单位名称" }}</div>
        <div
          class="tab_two cursor_pointer flex align-items justify-content"
          @click="zongpaxu(0)"
        >
          <div>{{ "留言总数" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img
                v-if="zongnum == 1"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img
                v-else-if="zongnum == 0"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img
                v-if="zongnum == 1"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img
                v-else-if="zongnum == 0"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="tab_three cursor_pointer flex align-items justify-content"
          @click="gongpaxu(1)"
        >
          <div>{{ "公开显示总数" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img
                v-if="gongnum == 1"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img
                v-else-if="gongnum == 0"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img
                v-if="gongnum == 1"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img
                v-else-if="gongnum == 0"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="tab_five cursor_pointer flex align-items justify-content"
          @click="bupaxu(2)"
        >
          <div>{{ "不公开显示总数" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img
                v-if="bunum == 1"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img
                v-else-if="bunum == 0"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img
                v-if="bunum == 1"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img
                v-else-if="bunum == 0"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="tab_six cursor_pointer flex align-items justify-content"
          @click="yipaxu(3)"
        >
          <div>{{ "已回复总数" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img
                v-if="yinum == 1"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img
                v-else-if="yinum == 0"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img
                v-if="yinum == 1"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img
                v-else-if="yinum == 0"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
        <div
          class="tab_eight cursor_pointer flex align-items justify-content"
          @click="weipaxu(4)"
        >
          <div>{{ "未回复总数" }}</div>
          <div class="qiehua">
            <div class="icon zhuan shang">
              <img
                v-if="weinum == 1"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img
                v-else-if="weinum == 0"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
            <div class="icon xia">
              <img
                v-if="weinum == 1"
                src="../../assets/img/xinban/shanghui.png"
                alt=""
              />
              <img
                v-else-if="weinum == 0"
                src="../../assets/img/xinban/shanglv.png"
                alt=""
              />
              <img v-else src="../../assets/img/xinban/shanghui.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 102px; width: 1200px"></div>
    <div class="mmt">
      <div class="tab_shen">
        <div class="hang" v-for="(item, index) in list" :key="index">
          <div class="tab_one">{{ item.name }}</div>
          <div
            class="tab_two cursor_pointer"
            @click="bumen(item.name, item.department_id)"
          >
            {{ item.count }}
          </div>
          <div
            class="tab_three cursor_pointer"
            @click="bumen(item.name, item.department_id)"
          >
            {{ item.open }}
          </div>
          <div class="tab_five">{{ item.notopen }}</div>
          <div
            class="tab_six cursor_pointer"
            @click="bumen(item.name, item.department_id, 1)"
          >
            {{ item.yihuifu }}
          </div>
          <div
            class="tab_eight cursor_pointer"
            @click="bumen(item.name, item.department_id, 2)"
          >
            {{ item.weihuifu }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api/modules/pj.js";
export default {
  data() {
    return {
      list: [],
      zongnum: 0,
      gongnum: 2,
      bunum: 2,
      yinum: 2,
      weinum: 2,
    };
  },
  mounted() {
    this.artmsgList();
  },
  methods: {
    // 跳转部门
    // 按部门查找帖子
    async bumen(name, id, num) {
      // 传递部门名字
      window.localStorage.setItem("names", name);
      window.localStorage.setItem("bmid", id);
      // 传递是否查找
      let a = 0;
      if (num != 0) {
        a = num;
      }
      window.localStorage.setItem("chazhao", a);
      this.$store.state.loginMessage.bm = id;

      let times = setTimeout(() => {
        let routeData = this.$router.resolve({
          name: "unit",
          query: {
            bm: id
          },
        });
        window.open(routeData.href, "_blank");
      }, 100);
      // this.jump("unit", this.$store.state.loginMessage);
    },
    // 未回复总数
    weipaxu(type) {
      if (this.weinum == 1) {
        this.weinum = 0;
      } else {
        this.weinum = 1;
      }
      (this.zongnum = 2),
        (this.gongnum = 2),
        (this.bunum = 2),
        (this.yinum = 2),
        // this.weinum= 2,
        this.paxu(type, this.weinum);
    },
    //已回复总数
    yipaxu(type) {
      if (this.yinum == 1) {
        this.yinum = 0;
      } else {
        this.yinum = 1;
      }
      (this.zongnum = 2),
        (this.gongnum = 2),
        (this.bunum = 2),
        // this.yinum= 2,
        (this.weinum = 2),
        this.paxu(type, this.yinum);
    },
    // 不公开显示总数
    bupaxu(type) {
      if (this.bunum == 1) {
        this.bunum = 0;
      } else {
        this.bunum = 1;
      }
      (this.zongnum = 2),
        (this.gongnum = 2),
        // this.bunum= 2,
        (this.yinum = 2),
        (this.weinum = 2),
        this.paxu(type, this.bunum);
    },
    // 公开显示总数
    gongpaxu(type) {
      if (this.gongnum == 1) {
        this.gongnum = 0;
      } else {
        this.gongnum = 1;
      }
      (this.zongnum = 2),
        //  this.gongnum= 2,
        (this.bunum = 2),
        (this.yinum = 2),
        (this.weinum = 2),
        this.paxu(type, this.gongnum);
    },
    // 总留言数
    zongpaxu(type) {
      if (this.zongnum == 1) {
        this.zongnum = 0;
      } else {
        this.zongnum = 1;
      }
      // this.zongnum= 2,
      (this.gongnum = 2),
        (this.bunum = 2),
        (this.yinum = 2),
        (this.weinum = 2),
        this.paxu(type, this.zongnum);
    },
    async paxu(type, num) {
      let res = await API.artmsgList({
        type: type,
        sort: num,
      }).then((res) => {
        if (res.code == 1) {
          this.list = [];
          this.list = res.data;
        }
      });
    },
    // type 0 = 留言总数, 1= 公开显示总数, 2= 不公开显示总数, 3= 已回复总数, 4= 未回复总数
    // sort	0 = 倒序(从大到小), 1= 正序(从小到大)
    async artmsgList() {
      let res = await API.artmsgList({
        type: 0,
        sort: 0,
      }).then((res) => {
        if (res.code == 1) {
          this.list = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
._leaveword {
  width: 1200px;
}

.pf {
  position: fixed;
  top: 341px;
  width: 1200px;
  z-index: 11;
}

.pf1 {
  position: fixed;
  top: 421px;
  width: 1200px;
  z-index: 12;
  border-bottom: none !important;
}

.tips {
  margin: 0px 0 0 0;
  color: #546569 !important;
  width: 1200px;
  height: 80px;
  background: -webkit-linear-gradient(top, #c9e9fe, #dbf0fe);

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }

  .fontone {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #18191a;
    letter-spacing: 0;
    font-weight: 700;
  }

  .fonttwo {
    font-family: PingFangSC-Regular;
    font-size: 14px;
    color: #c90e0e;
    letter-spacing: 0;
    font-weight: 400;
  }
}

.tab {
  border: 1px solid #78a6dc;
}

// 表头
.tab_head {
  width: 100%;
  height: 52px;
  background: #e8effa;
  display: flex;
  align-items: center;
  padding: 0 27px 0 28px;
  font-family: PingFangSC-Regular;
  font-size: 15px;
  color: #0653ac;
  letter-spacing: 0;
  font-weight: 400;

  .tab_one {
    flex: 1;
    text-align: center;
  }

  .tab_two {
    flex: 1;
    text-align: center;
  }

  .tab_three {
    flex: 1;
    text-align: center;
  }

  .tab_five {
    flex: 1;
    text-align: center;
  }

  .tab_six {
    flex: 1;
    text-align: center;
  }

  .tab_eight {
    flex: 1;
    text-align: center;
  }
}

.mmt {
  border: 1px solid #78a6dc;
  border-top: none !important;
}

.tab_shen {
  // border-top: 0px solid #78A6DC;

  .hang {
    width: 100%;
    display: flex;
    align-items: center;
    font-family: PingFangSC-Regular;
    font-size: 15px;
    letter-spacing: 0;
    font-weight: 400;
    padding: 0 27px 0 28px;
    height: 52px;
    color: #191a1c;

    &:hover {
      background: #2670c5 !important;
      font-family: PingFangSC-Medium;
      font-size: 15px !important;
      color: #ffffff !important;
      letter-spacing: 0;
      font-weight: 600;
    }
  }

  .hang:nth-child(even) {
    background-color: #f1f1f1;
  }

  .tab_one {
    flex: 1;
    text-align: center;
  }

  .tab_two {
    flex: 1;
    text-align: center;
  }

  .tab_three {
    flex: 1;
    text-align: center;
  }

  .tab_five {
    flex: 1;
    text-align: center;
  }

  .tab_six {
    flex: 1;
    text-align: center;
  }

  .tab_eight {
    flex: 1;
    text-align: center;
  }
}

.fontThree {
  font-family: PingFangSC-Medium;
  font-size: 15px;
  color: #36383a;
  letter-spacing: 0;
  font-weight: 500;
}

.qiehua {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 5px;

  .icon {
    width: 14px;
    height: 9px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .zhuan {
    transform: rotate(180deg);
  }
}

.shang {
  display: flex;
  align-items: flex-end;
  margin: 0 0 1px 0;
}

.xia {
  width: 14.4px;
  margin: 1px 0 0 0;
  display: flex;
  align-items: flex-start;
}
</style>