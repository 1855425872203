// 评价
import request from '../request'

export default class Pj {

    /**
* 回复满意度统计
* @param {*} params
* @returns
*/
    static satisfiedList(params) {
        return request.get('/api/v1.articlecurrency/satisfiedList', { params })
    }
    /**
* 留言统计
* @param {*} params
* @returns
*/
    static artmsgList(params) {
        return request.get('/api/v1.articlecurrency/artmsgList', { params })
    }
}